<template>
  <div class="text-center pa-4">
    <v-dialog
      v-model="showProductDialog"
      transition="dialog-bottom-transition"
      fullscreen
    >
      <v-card>
        <v-toolbar>
          <v-btn icon="mdi-close" @click="callback()"></v-btn>

          <v-toolbar-title>Outbound Orders Information</v-toolbar-title>

          <v-spacer></v-spacer>

          <v-toolbar-items>
            <JsonCSV :data="productInfo" name="outbound_orders_info.csv" class="btn mr-4">
              Download As CSV
            </JsonCSV>
          </v-toolbar-items>
        </v-toolbar>

        <v-container class="mt-4 px-4">
          <v-row class="table-header">
            <!-- Table Headers -->
            <v-col cols="3"><strong>Name</strong></v-col>
            <v-col cols="3"><strong>Description</strong></v-col>
            <v-col cols="2"><strong>Options</strong></v-col>
            <v-col cols="2"><strong>Required</strong></v-col>
            <v-col cols="2"><strong>Example</strong></v-col>
          </v-row>
          <v-divider></v-divider>

          <!-- Table Rows -->
          <v-row v-for="info in productInfo" :key="info.Name" class="table-row">
            <v-col cols="3">{{ info.name }}</v-col>
            <v-col cols="3">{{ info.description }}</v-col>
            <v-col cols="2">{{ info.options }}</v-col>
            <v-col cols="2">{{ info.required }}</v-col>
            <v-col cols="2">{{ info.example }}</v-col>
          </v-row>
        </v-container>
      </v-card>
    </v-dialog>
  </div>
</template>

<script setup>
import { ref, onMounted } from "vue";
import JsonCSV from "vue-json-csv";
import useOutboundOrders from "../../hooks/useOutboundOrders";

let showProductDialog = ref(true);

const props = defineProps({
  callback: Boolean,
});

const { fetchUploaderInfo, uploaderInfo } = useOutboundOrders();

let productInfo = ref([]); // Initialize productInfo as an empty array

onMounted(async () => {
  await fetchUploaderInfo();
  productInfo.value = uploaderInfo.value; // Assign the fetched data to productInfo
});
</script>

<style scoped>
.btn {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  font-weight: 500;
  font-size: 15px;
  cursor: pointer;
  padding: 6px 16px;
  text-transform: uppercase;
}

.btn:hover {
  background-color: rgba(0, 0, 0, 0.04);
}

.table-header {
  padding: 10px 0;
}

.table-row {
  padding: 10px 0;
  border-bottom: 1px solid #e0e0e0;
}

strong {
  font-weight: bold;
}
</style>
