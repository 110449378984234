<script setup>
import { ref, onMounted, watch } from "vue";
import CSVUploader from "./CsvUploader.vue";
import pageTitle, { setPageTitle } from "@/utils/pageTitle";
import useCsvUploader from "@/hooks/useCsvUpload";
import { useRouter, useRoute } from "vue-router";
import debounce from "lodash/debounce";
import ProductInfo from "@/components/csvuploader/ProductInfo.vue"
import InboundInfo from "@/components/csvuploader/InboundInfo.vue"
import OutboundInfo from "@/components/csvuploader/OutboundInfo.vue"

const {
  uploadTypes,
  fetchUploadTypes,
  clients,
  fetchClients,
  uploadCsvToServer,
} = useCsvUploader();

const route = useRoute();
const router = useRouter();

const isLoadingClients = ref(false); // Add a reactive state for loading status

onMounted(async () => {
  await fetchClients(); // Initially fetch clients without filter
  fetchUploadTypes();
  getUrlQueryParams();
  setPageTitle("Import Csv files of your clients.");
});

const getUrlQueryParams = async () => {
  await router.isReady();
  const { client_id, uploader } = route.query;

  if (client_id !== undefined) {
    client.value = Number(client_id);
    await fetchClients(client.value); // Fetch clients with filter
  }

  if (uploader !== undefined) {
    uploaderTemplate.value = uploader;
  }
};

const uploaderTemplate = ref(null);
const client = ref(null);
let showError = ref(false);
let errMsg = [];

const fetchClientsWithLoading = async (query) => {
  isLoadingClients.value = true; // Set loading status to true immediately
  await fetchClients(query);
  isLoadingClients.value = false; // Set loading status to false after fetch is complete
};

const debouncedFetchClients = debounce((query) => {
  fetchClientsWithLoading(query);
}, 300);

watch(client, (newClient) => {
  if (newClient) {
    isLoadingClients.value = true; // Set loading status to true immediately before debounce
    debouncedFetchClients(newClient);
  }
});

async function handleCallback(data, createImporter) {
  try {
    data.client_id = client.value;
    const res = await uploadCsvToServer(uploaderTemplate.value, data);
  } catch (error) {
    errMsg = error.response?.data.data;
    showError.value = true;
    createImporter();
    window.scroll(0, 0);
  }
}

const resetModals = () => {
  uploaderTemplate.value = null;
  client.value = null;
  showError.value = false;
};

let showProductDialog = ref(false);
let showInboundDialog = ref(false);
let showOutboundDialog = ref(false);

const toggleInfoDialogs = () => {
  if (uploaderTemplate.value === 'products') {
    showProductDialog.value = true;
  } else if (uploaderTemplate.value === 'inbound_orders') {
    showInboundDialog.value = true;
  } else if (uploaderTemplate.value === 'outbound_orders') {
    showOutboundDialog.value = true;
  }
}

</script>

<template>
  <v-card-title>{{ pageTitle }}</v-card-title>
  <v-card-subtitle>Use this section to manage CSV uploads.</v-card-subtitle>



  <v-alert v-model="showError" title="CSV Import Failed!" type="error" closable class="ml-4 mr-6 mt-10">
    <div class="alertSection">
      <br />
      <ul v-for="error in errMsg" :key="error">
        <li>{{ error }}</li>
      </ul>
      <br />
    </div>
  </v-alert>

  <div v-if="showProductDialog">
    <ProductInfo :callback="() => {
    showProductDialog = !showProductDialog
  }" />
  </div>

  <div v-if="showInboundDialog">
    <InboundInfo :callback="() => {
    showInboundDialog = !showInboundDialog
  }" />
  </div>

  <div v-if="showOutboundDialog">
    <OutboundInfo :callback="() => {
    showOutboundDialog = !showOutboundDialog
  }" />
  </div>

  <div class="container">

    <div v-if="uploaderTemplate && client" class="cancel-button">
      <v-icon @click="toggleInfoDialogs()" icon="mdi-information-slab-circle-outline" size="42"
        color="#7a5ef8" class="pb-1"></v-icon>
      <v-btn class="ml-3" color="#7a5ef8" @click="resetModals">Cancel</v-btn>
    </div>


    <div v-if="!uploaderTemplate || !client" class="d-flex mb-16 mt-12 select-components">
      <div class="d-flex select-uploader mt-4">
        <p class="text-h6 mt-2 ml-4 mr-4">Upload Type:</p>
        <v-select density="compact" label="Please select an uploader" v-model="uploaderTemplate" :items="uploadTypes"
          item-title="title" item-value="value" />
      </div>

      <div class="d-flex select-client mt-4">
        <div class="text-h6 mt-2 ml-4 mr-4">Client:</div>
        <v-autocomplete density="compact" label="Please select a client"
          @update:search="(query) => debouncedFetchClients(query)" :items="clients" item-title="name" item-value="id"
          v-model="client" :no-data-text="isLoadingClients ? 'Loading...' : 'No results found'" />
      </div>
    </div>

    <CSVUploader class="mt-10" :uploaderTemplate="uploaderTemplate" :client="client" v-if="uploaderTemplate && client"
      :callback="handleCallback" />
  </div>
</template>

<style scoped>
.v-icon {
  cursor: pointer;
  height: 42px;
  width: 42px;
}

.select-components {
  column-gap: 5%;
  margin: 5%;
}

.alertSection {
  height: 150px;
  overflow: auto;
}

ul li {
  line-height: 1.7;
}

.container {
  position: relative;
}

.cancel-button {
  display: flex;
  justify-content: flex-end;
  /* align-items: flex-start; */
  width: 98%;
}

.select-uploader {
  min-width: 200px;
  flex-basis: 50%;
}

.select-client {
  min-width: 200px;
  flex-basis: 50%;
}

@media (max-width: 768px) {
  .v-select {
    margin: 4%;
  }

  .select-uploader {
    width: 100%;
    flex-direction: column;
  }

  .select-client {
    width: 100%;
    flex-direction: column;
  }

  .select-components {
    flex-direction: column;
  }
}
</style>
